<template>
  <NuxtLoadingIndicator color="var(--q-primary)" />
  <NuxtLayout>
    <NuxtPage :transition="{ mode: 'in-out' }" />
  </NuxtLayout>
  <DialogConfirm />
</template>
<script setup lang="ts">
import { useHead } from "#imports";

useHead({
  titleTemplate: (x) => (x ? `Hazcheck Detect | ${x}` : "Hazcheck Detect"),
});
</script>
