import validate from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt/dist/pages/runtime/validate.js";
import is_45logged_45in_45global from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/middleware/isLoggedIn.global.ts";
import manifest_45route_45rule from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  is_45logged_45in_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "analytics-permission": () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/middleware/analyticsPermission.ts"),
  "case-exists": () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/middleware/caseExists.ts"),
  "case-permission": () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/middleware/casePermission.ts"),
  "case-status-redirect": () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/middleware/caseStatusRedirect.ts"),
  "config-permission": () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/middleware/configPermission.ts"),
  "data-block-exists": () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/middleware/dataBlockExists.ts"),
  "email-template-exists": () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/middleware/emailTemplateExists.ts"),
  "email-template-permission": () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/middleware/emailTemplatePermission.ts"),
  "incident-exists": () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/middleware/incidentExists.ts"),
  "incident-permission": () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/middleware/incidentPermission.ts"),
  "library-exists": () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/middleware/libraryExists.ts"),
  "policy-exists": () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/middleware/policyExists.ts"),
  "rule-exists": () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/middleware/ruleExists.ts"),
  "rule-permission": () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/middleware/rulePermission.ts"),
  "service-hook-exists": () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/middleware/serviceHookExists.ts"),
  "template-exists": () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/middleware/templateExists.ts"),
  "template-group-exists": () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/middleware/templateGroupExists.ts")
}